import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const TwelveA80GReg = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> 12A &amp; 80G Registration </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> 12A &amp; 80G Registration </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <img src="assets/images/reg.jpg" alt="" />
              <h1>12A &amp; 80G Registration</h1>
              <br />
              <h3>12A Registration</h3>
              <p>
                Income of Trusts/NGOs working for public utility and charitable
                purpose are normally exempted from Income Tax. However, there
                are certain rules and regulations and guidelines which an NGO
                must follow to get such exemption.
              </p>
              <p>
                Section 12A of the Income Tax Act deals with application for
                such exemption.
              </p>
              <p>
                This is one time registration. If the NGO is not registered,
                income tax is payable on surplus during the year.
              </p>
              <p>This exemption is available for all non-profit NGOs.</p>
              <h5>When to apply</h5>
              <p>
                Application for registration under section 12A can be applied
                just after registration of the NGO.
              </p>
              <h5>Benefits of 12A Registration</h5>
              <ul>
                <li> Income will be exempted for taxation. </li>
                <li>
                  {' '}
                  Benefits in taking grants from Govt./Abroad/ other Agencies.{' '}
                </li>
                <li> Benefit in FCRA registration. </li>
              </ul>
              <h5>Documents to be Attached</h5>
              <ul>
                <li>
                  Registration Certificate and MOA /Trust Deed (two copies-self
                  attested by NGO head)
                </li>
                <li>NOC from Landlord (from registered office)</li>
                <li>A Copy of PAN card of your NGO</li>
                <li>
                  Photocopy of Electricity Bill / House tax Receipt /Water Bill.
                </li>
                <li>
                  Evidence of welfare activities carried out &amp; Progress
                  Report since inception or last 3 years
                </li>
                <li>
                  Books of Accounts, Balance Sheet &amp; ITR (if any), since
                  inception or last 3years
                </li>
                <li>List of donors along with their address and PAN</li>
                <li>
                  List of governing body or board of trustees members with their
                  contact details
                </li>
                <li>Original RC and MOA /Trust Deed for verification</li>
                <li>Authority letter in favor of NGO Factory</li>
                <li>
                  Any other document or affidavit / undertaking, if extra
                  information is by the Income Tax department
                </li>
              </ul>

              <h3>80G Registration</h3>
              <p>
                We are offering All services to our prestigious customers which
                are delivered accurately and precisely. Our experienced
                professionals are capable of developing efficient and
                cost-effective tax strategies that can help our clients in
                managing new tax laws according to the legislation and
                prescribed government norms and guidelines.
              </p>
              <p>
                <strong>&#42; Please Attach This Documents </strong>
              </p>
              <ul>
                <li>Company Registration</li>
                <li>Company Annual Return</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

TwelveA80GReg.propTypes = {};

export default TwelveA80GReg;

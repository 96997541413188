import React from 'react';
import PropTypes from 'prop-types';

const Maintenance = props => {
  return (
    <section id="maintenance" className="section-padding">
      <div className="center-text">
        <i className="fa fa-5x fa-cog fa-spin"></i>
        <span className="sr-only">Loading...</span>
        <h3>Under Construction</h3>
        <div className="underline"></div>
        <p>This Page is under construction.</p>
      </div>
    </section>
  );
};

Maintenance.propTypes = {};

export default Maintenance;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Advisory = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> Business Advisory </h1>
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/itr"> Business Advisory </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/advisory.jpg" alt="" />
              <h2> Business Advisory </h2>
              <br />
              <p>
                We are offering All services to our prestigious customers which
                are delivered accurately and precisely. Our experienced
                professionals are capable of developing efficient and
                cost-effective tax strategies that can help our clients in
                managing new tax laws according to the legislation and
                prescribed government norms and guidelines.
              </p>
              <br />
              <p>
                <strong>Please Attach these Documents....</strong>
              </p>

              <ul>
                <li>Company Registration</li>
                <li>Company Annual Return</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Advisory.propTypes = {};

export default Advisory;

import React from 'react';
import PropTypes from 'prop-types';

const Footer = props => {
  return (
    <section className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-4 section-padding">
            <div className="row">
              <h4 className="footer-heading">Popular Links</h4>
            </div>
            <div className="row" id="links">
              <ul>
                <a href="/itr">
                  <li>Income Tax Return</li>
                </a>
                <a href="/gst">
                  <li>GST Return</li>
                </a>
                <a href="/tds-tcs">
                  <li>TDS/TCS Return</li>
                </a>
                <a href="/startup">
                  <li>Stratup Services</li>
                </a>
                <a href="/registrations">
                  <li>Registrations</li>
                </a>
                <a href="/advisory">
                  <li>Business Advisory</li>
                </a>
              </ul>
            </div>
          </div>
          <div className="col-md-4 section-padding">
            <div className="row">
              <h4 className="footer-heading">Contact Us</h4>
            </div>
            <div className="row">
              <div className="contact_add">
                <p>
                  <i className="fa fa-2x fa-map-marker"></i>&nbsp;Mishra Anurag
                  &amp; Comapny, 159, Ground Floor, Sector 2B, Vaishali,
                  Ghaziabad, UP, India &#45; 201010
                </p>
                <a href="/"></a>
              </div>
              <div className="contact_call">
                <a href="tel:+91 8285162228">
                  <span className="fa fa-phone"></span>&nbsp;&nbsp;8285162228
                </a>{' '}
                &frasl; <a href="tel:+91 8317045105">8317045105</a> <br />
                <span className="fa fa-envelope"></span>&nbsp;&nbsp;
                <a href="mailto:caanuragmishra@gmail.com">
                  mail@caanuragmishra.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 section-padding">
            <div className="row">
              <h4 className="footer-heading">Newsletter Subscription</h4>
            </div>
            <div className="row" id="newsletter">
              <form className="newsletter-form" method="POST">
                <div className="input-group">
                  <input
                    className="form-control"
                    name="email"
                    placeholder="youremail@domain.com"
                    type="email"
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn subscribe-btn btn-blue"
                      type="button"
                    >
                      Subscribe
                    </button>
                  </span>
                </div>
                <p>
                  &#42;&nbsp;Join our subscribers list to get the latest news,
                  updates and special offers directly into your inbox.
                </p>
              </form>
            </div>
            <div className="row">
              <h4 className="footer-heading">Get Connected</h4>
            </div>
            <div className="row">
              <div className="social-icons">
                <a href="/ud" className="fa fa-linkedin"></a>
                <a href="/ud" className="fa fa-twitter"></a>
                <a href="/ud" className="fa fa-google-plus"></a>
                <a href="/ud" className="fa fa-facebook"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg">
        <div className="container" id="footer-bottom">
          <div className="row">
            <div className="col-md-6" id="copyright">
              &copy; 2018 Mishra Anurag &amp; Company &#124; All Rights Reserved
            </div>
            <div className="col-md-6" id="quick-links">
              <ul>
                <a href="/">
                  <li>Home &nbsp;</li>
                </a>
                &#124;
                <a href="/aboutus">
                  <li>About Us &nbsp;</li>
                </a>
                &#124;
                <a href="/important-links">
                  <li>Important Links &nbsp;</li>
                </a>
                &#124;
                <a href="/career">
                  <li>Career &nbsp;</li>
                </a>
                &#124;
                <a href="#contact">
                  <li>Contact Us </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Footer.propTypes = {};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';

const Carousel = props => {
  return (
    <div className="container-fluid wow slide" style={{ padding: '0px' }}>
      <div className="row">
        <div className="col-md-12 custom">
          <div className="owl-carousel">
            <div className="item">
              <img
                src="assets/images/a.jpg"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
            <div className="item">
              <img
                src="assets/images/itr.jpg"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
            <div className="item">
              <img
                src="assets/images/loan.jpg"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {};

export default Carousel;

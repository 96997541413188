import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Accounting = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> Accounting Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/itr"> Accounting Services </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/accounting.png" alt="" />
              <h2> Accounting Services </h2>
              <br />
              <p>
                We are offering Accounting services to our prestigious customers
                which are delivered accurately and precisely. Our experienced
                professionals are capable of developing efficient and
                cost-effective tax strategies that can help our clients in
                managing new tax laws according to the legislation and
                prescribed government norms and guidelines.Get well trained
                Accountant at your place. please provide following details.
              </p>
              <p>
                We carry out accounting services as per International Accounting
                Standards and as per IFRS requirements. The complex data
                management of any organization is tedious and worry some subject
                of the organization or an entrepreneurs. We use and outsource
                various software, applications and technology to provide
                specific and scientific solutions for making right decisions at
                the right time. This depends on the needs of an entrepreneurs
                and organization.
              </p>
              <p>
                We at MISHRA ANURAG &amp; COMPANY, offer accounting outsourcing
                services after complete study on your particular requirement and
                find inventive solutions using scientific and business analysis
                techniques, market study, international accounting standards and
                practices through application of technology. The scientific
                management and financial management skills will give you the
                competitive cutting edge solutions to compete in your market
                place with the help of right method and information.
              </p>
              <p>
                We setup accounting department and train accounting and finance
                staffs for new investors, overseas companies, business houses
                and business owners. We design, implement and consult the
                corporates to provide insightful management information reports
                for day-to-day decision making process to the management. We
                oversee the accounting department functions for our local and
                overseas clients for effective control of procurement process,
                inventory management, cost control and sales order management
                process.
              </p>
              <p>
                We undertake accounting and bookkeeping services as per
                client\'s requirement and prepare management accounts reports as
                per international accounting standards and practices and advice
                client\'s using financial management ratio analysis, forecasts
                and tools. We also assist clients on creation of budgets and
                cash flow forecasts. If required, we advise to adopt best
                systems, procedures and internal control system to minimize cost
                and maximize profitability of the organization.
              </p>
              <br />
              <br />
              <h3>
                OUR EXPERTISE WILL HELP YOU MORE SPECIFIC, ACCURATE, AND COST
                EFFECTIVE AND VALUE ADDED SOLUTIONS ARE
              </h3>
              <ul>
                <li>Accounting Services For Small Business</li>
                <li>Bookkeeping Services</li>
                <li>Bookkeeping Services In UAE</li>
                <li>Accounting Outsourcing Services In UAE</li>
                <li>Accounting Services In UAE</li>
                <li>Business Transaction Process Services</li>
                <li>Business accounting Services</li>
                <li>Back Office And Outsourcing Services</li>
                <li>Data Management Services</li>
                <li>Accounting Department Setup And Implementation Services</li>
                <li>Financial Director Services</li>
                <li>Management Information Report Implementation Services</li>
                <li>
                  Management Accounts Preparation As Per International
                  Accounting Standards
                </li>
                <li>Payroll Processing Services</li>
                <li>Part-time Accounting Services</li>
                <li>Backlog Accounting Services</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Accounting.propTypes = {};

export default Accounting;

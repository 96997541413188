import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const AuditAssurance = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> AUDITING SERVICES </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> AUDITING SERVICES </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/reg.jpg" alt="" />
              <h2> AUDITING SERVICES </h2>
              <br />
              <p>
                We offer a full range of auditing services and tools designed to
                cover all aspects of your business. Customers, suppliers, staff,
                and the global community are increasingly demanding that
                processes, products and services comply with local, global and
                industry standards. At MISHRA ANURAG &amp; Company's Assurance
                Services, we strive to provide customers with the best possible
                audit experience, and a means for managing risk, achieving
                compliance and driving overall business improvement within your
                organization.
              </p>
              <br />
              <h3>The Expertise That Helps You Compete</h3>
              <p>
                No matter what industries your business operates within, MISHRA
                ANURAG &amp; Company has specialized teams that can offer you
                deep expertise in sector-specific auditing services, helping to
                ensure that your processes and operations meet and exceed
                regulatory and standard\'s requirements. A truly global service
                provider with over 100 auditors world-wide, we are able to
                provide auditing solutions anywhere your business operates and
                ready to put you on the path towards business excellence.
              </p>
              <br />
              <h3>Supply Chain Audits</h3>
              <p>
                At MISHRA ANURAG &amp; Company we take great pride in our
                staff\'s ability to provide world-class auditing services across
                the entire supply chain. With our supply chain audit solutions,
                we are able to meet your organizations unique specifications,
                and can ensure that all your business partners are able to
                consistently meet those specifications.
              </p>
              <br />
              <h3>IT Audit</h3>
              <p>
                IT Auditing has become a standard certification many employers
                and government organization require. InfoSec Institute has been
                running IT Auditing training for over a decade. You'll learn
                applied skills from the top instructors in the industry - while
                being assured you will receive the best test preparation
                available.
              </p>
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

AuditAssurance.propTypes = {};

export default AuditAssurance;

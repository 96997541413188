import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Registrations = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> Company &amp; Law Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> Registrations </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/reg.jpg" alt="" />
              <h2> COMPANY LAW SERVICES </h2>
              <br />
              <p>
                We are a well recognized Tax Consultants, providing
                consultations and services in Company Law Matters. Our services
                are customized in accordance to your specific requirements.
              </p>
              <h3>
                <u>Private Limited Company Registration</u>
              </h3>
              <br />
              <p>
                Private Limited Companies are those types of companies where
                minimum number of members is two and maximum number is two
                hundred. A private limited company has the limited liability of
                members but at the same time it has many characteristics as
                those of a partnership firm. A private limited company has all
                the advantages of partnership namely flexibility, greater
                capital combination of different and diversified abilities,
                etc., and at the same time it has advantages of limited
                liability, greater stability and legal entity. In this sense, a
                private limited company stands between partnership and widely
                owned public company. Identifying marks of a private limited
                company are name, number of members, shares, formation,
                management, directors and meetings, etc., The maximum number of
                directors shall have to be mentioned in the Articles of
                Association. In the grand of privileges and exemptions, the
                Companies Act has drawn a distinction between an independent
                private company and other private company which is a subsidiary
                to the other public company.
              </p>
              <h3>ADVANTAGES OF PRIVATE LIMITED COMPANY</h3>
              <br />
              <h5>Separate Legal Entity</h5>
              <p>
                A company is a legal entity and a juristic person established
                under the Act. Therefore a company form of organization has wide
                legal capacity and can own property and also incur debts. The
                members (Shareholders/Directors) of a company have no liability
                to the creditors of a company for such debts.
              </p>
              <h5>Uninterrupted Existance</h5>
              <p>
                A company has 'perpetual succession', that is continued or
                uninterrupted existence until it is legally dissolved. A
                company, being a separate legal person, is unaffected by the
                death or other departure of any member but continues to be in
                existence irrespective of the changes in membership.
              </p>
              <h5>Borrowing Capacity</h5>
              <p>
                A company enjoys better avenues for borrowing of funds. It can
                issue debentures, secured as well as unsecured and can also
                accept deposits from the public, etc. Even banking and financial
                institutions prefer to render large financial assistance to a
                company rather than partnership firms or proprietary concerns.
              </p>
              <h5>Easy Transferability</h5>
              <p>
                Shares of a company limited by shares are transferable by a
                shareholder to any other person. Filing and signing a share
                transfer form and handing over the buyer of the shares along
                with share certificate can easily transfer shares.
              </p>
              <h5>Owning Property</h5>
              <p>
                A company being a juristic person, can acquire, own, enjoy and
                alienate, property in its own name. No shareholder can make any
                claim upon the property of the company so long as the company is
                a going concern.
              </p>
              <br />
              <br />
              <h3>
                <u>Limited Liability Partnership Registration</u>
              </h3>
              <br />
              <p>
                {' '}
                Limited Liability Partnership has been introduced in India by
                way of Limited Liability Partnership Act, 2008. The basic
                premise behind the introduction of Limited Liability Partnership
                (LLP) is to provide a form of business organization that is
                simple to maintain while at the same time providing limited
                liability to the owners. A Limited Liability Partnership
                combines the advantage of both the Company and Partnership into
                a single form of organization and one partner is not responsible
                or liable for another partner's misconduct or negligence.
                Therefore, all partners have a form of limited liability for
                each individual's protection within the partnership, similar to
                that of the shareholders of a corporation. However, unlike
                corporate shareholders, the partners have the right to manage
                the business directly. An LLP also limits the personal liability
                of a partner for the errors, omissions, incompetence, or
                negligence of the LLP's employees or other agents. LLP is one of
                the easiest form of business to incorporate and manage.
              </p>
              <h3>ADVANTAGES OF LIMITED LIABILITY PARTNERSHIP</h3>
              <br />
              <h5>Separate Legal Entity</h5>
              <p>
                A LLP is a legal entity and a juristic person established under
                the Act. Therefore a LLP form of organization has wide legal
                capacity and can own property and also incur debts. The Partners
                of a LLP have no liability to the creditors of a LLP for such
                debts.
              </p>
              <h5>Uninterrupted Existance</h5>
              <p>
                A LLP has 'perpetual succession', that is continued or
                uninterrupted existence until it is legally dissolved. A LLP,
                being a separate legal person, is unaffected by the death or
                other departure of any Partner but continues to be in existence
                irrespective of the changes in Partnership.
              </p>
              <h5>Audit NOT Required</h5>
              <p>
                A LLP does not require audit if it has less than Rs.2.00 Crores
                of turnover and less than Rs.25 lakhs of capital contribution.
                Therefore, LLPs are ideal for startups and small businesses that
                are just starting their operations and want to have minimal
                regulatory compliance related formalities.
              </p>
              <h5>Easy Transferability</h5>
              <p>
                The ownership of a LLP can be easily transferred to another
                person by inducting them as a Designated Partner of the LLP. LLP
                is a separate legal entity separate from its Managing Partners,
                so by changing the Managing Partners, the ownership of the LLP
                can be changed.
              </p>
              <h5>Owning Property</h5>
              <p>
                A LLP being a juristic person, can acquire, own, enjoy and
                alienate, property in its own name. No Partner can make any
                claim upon the property of the LLP so long as the LLP is a going
                concern.
              </p>
              <h5>Limited Liability</h5>
              <p>
                Limited Liability means the status of being legally responsible
                only to a limited amount for debts of a LLP. Unlike
                proprietorships and partnerships, in a LLP the liability of the
                members in respect of the LLP's debts is limited.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Registrations.propTypes = {};

export default Registrations;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Gst = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> GST Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> GST Services </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src="assets/images/gst.jpeg" alt="" />
              <h3> GST- GOODS AND SERVICE TAX MIGRATION </h3>
              <br />
              <h4>Migration of Existing Tax Payers</h4>
              <ul className="text-justify">
                <li>
                  Existing registrants either with States or with Centre to be
                  migrated to GSTIN - Process already initiated
                </li>
                <li>
                  VAT registration data to be used for migration of dealers in
                  goods
                </li>
                <li>
                  Service Tax registration data to be used for migration of
                  service providers
                </li>
                <li>Validation of existing registration information by GSTN</li>
                <li>
                  Verification /updation of migrated data by existing
                  registrants within a specified period
                </li>
                <li>Issuance of GSTIN by GSTN</li>
                <li>
                  Verification by Centre/State Authorities after issuance of
                  GSTIN
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Gst.propTypes = {};

export default Gst;

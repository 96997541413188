import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';
import App from '../App';
import {
  AboutUs,
  Home,
  Accounting,
  Advisory,
  Advisory2,
  AuditAssurance,
  Compliances,
  Fcra,
  Gst,
  Iec,
  ImportantLinks,
  Itr,
  Maintenance,
  NgoTrust,
  PanTan,
  Registrations,
  TdsTcs,
  Trademark,
  TwelveA80GReg,
} from '../pages';

const MainRoutes = props => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />}></Route>
      <Route path="/aboutus" exact element={<AboutUs />}></Route>
      <Route path="/accounting" exact element={<Accounting />}></Route>
      <Route path="/ud" exact element={<Maintenance />}></Route>
      <Route path="/advisory" exact element={<Advisory />}></Route>
      <Route path="/advisory2" exact element={<Advisory2 />}></Route>
      <Route path="/audit-assurance" exact element={<AuditAssurance />}></Route>
      <Route path="/compliances" exact element={<Compliances />}></Route>
      <Route path="/fcra" exact element={<Fcra />}></Route>
      <Route path="/gst" exact element={<Gst />}></Route>
      <Route path="/iec" exact element={<Iec />}></Route>
      <Route path="/important-links" exact element={<ImportantLinks />}></Route>
      <Route path="/itr" exact element={<Itr />}></Route>
      <Route path="/ngo-trust" exact element={<NgoTrust />}></Route>
      <Route path="/pan-tan" exact element={<PanTan />}></Route>
      <Route path="/registrations" exact element={<Registrations />}></Route>
      <Route path="/tds-tcs" exact element={<TdsTcs />}></Route>
      <Route path="/trademark" exact element={<Trademark />}></Route>
      <Route path="/12a80greg" exact element={<TwelveA80GReg />}></Route>
      {/* <Navigate to="/not-found" status="404" /> */}
    </Routes>
  );
};

MainRoutes.propTypes = {};

export default MainRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Fcra = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> FCRA Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> FCRA Services </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img
                src="assets/images/free-business-plan-templates.jpg"
                alt=""
              />
              <h2> FCRA Services </h2>
              <br />
              <h3>FCRA Registration</h3>
              <p>
                An organisation having a definite cultural, economic,
                educational, religious or social programme shall accept foreign
                contribution, only after such organisation obtains a certificate
                of registration from the Central Government.
              </p>
              <p>
                Any person, if it is not registered with the Central Government,
                can accept foreign contribution only after obtaining the prior
                permission of the Central Government and such prior permission
                shall be valid for the specific purpose for which it is obtained
                and from the specific source.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Fcra.propTypes = {};

export default Fcra;

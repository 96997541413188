import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Compliances = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> Company &amp; Law Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> Compliances </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/reg.jpg" alt="" />
              <h2> COMPANY LAW SERVICES </h2>
              <br />
              <h3>
                <u>Compliances</u>
              </h3>
              <br />
              <ul>
                <li>Formation &amp; Registration of Company </li>
                <li>E- Filing of Documents with MCA.</li>
                <li>Compliance under Companies Act, 2013 </li>
                <li>Getting the Company name changed.</li>
                <li>Sell / Purchase of Companies</li>
                <li>Creation of Database of shareholders</li>
                <li>Obtaining of Digital Signature Certificate</li>
                <li>
                  Formation of Section 8 Company (Non profit making
                  organization)
                </li>
                <li>
                  Declaration for registration of Memorandum and Articles of
                  Section 8 companies{' '}
                </li>
                <li>Register of Attendance of Board and General Meetings </li>
                <li>Minutes Books of Board and General Meetings etc </li>
                <li>Getting Charge Registered </li>
                <li>
                  Liaison with Registrar of Companies &amp; Regional Directors{' '}
                </li>
                <li>Getting Director Identification Number (DIN) </li>
                <li>
                  Maintenance of statutory records prescribed under Companies
                  Act, 2013.{' '}
                </li>
                <li>All matters relating to Company Law Board.</li>
                <li>
                  All matters relating to Registration of Trademark, Logo,
                  Copyrights, Patents and Designs.{' '}
                </li>
                <li>
                  All matters related to the Ministry of Corporate Affairs and
                  Registrar of Companies.
                </li>
                <li>
                  Drafting of Director's Report, Corporate Governance Report,
                  Annual Report presentation Periodical advisory and / or
                  retainer basis for Private Company / Closely held Company /
                  Listed Company / Joint Venture Company / Section 8 Company for
                  Company law related matters
                </li>
              </ul>
              <p>
                <strong>* Please Attach This Documents</strong>
              </p>
              <ul>
                <li>Names of Proposed Company (At least 4 Names)</li>
                <li>
                  Director,s Photo and ID &amp; Address / DIN of Directors
                </li>
                <li>
                  Rent Agreement / Sale deed in the name of Proposed company for
                  Registered address of company.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Compliances.propTypes = {};

export default Compliances;

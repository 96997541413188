import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
  Maintenance as MaintenanceOrg,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Maintenance = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <MaintenanceOrg />
      <Footer />
    </div>
  );
};

Maintenance.propTypes = {};

export default Maintenance;

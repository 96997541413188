import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const ImportantLinks = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <section className="important-links">
        <div className="content-header">
          <div className="content-title">
            <h3>Important Links </h3>
            <div className="content-title-underline"></div>
            <ul className="breadcrumb">
              <li>
                <a href="index">Home</a>
              </li>
              <li>
                <a href="important-links">Important Links</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container-fluid section-padding column">
          <div className="row">
            <div className="col-md-4">
              <ul className="list">
                <li>
                  <a href="http://www.mca.gov.in/" target="_blank">
                    Ministry of Corporate Affairs
                  </a>
                </li>
                <li>
                  <a href="https://www.sebi.gov.in/" target="_blank">
                    Securities and Exchange Board of India
                  </a>
                </li>
                <li>
                  <a href="http://www.servicetax.gov.in/" target="_blank">
                    Income Tax Department of India
                  </a>
                </li>
                <li>
                  <a href="http://www.cbic.gov.in/" target="_blank">
                    Directorate of Service Tax
                  </a>
                </li>
                <li>
                  <a href="http://www.cbic.gov.in/" target="_blank">
                    Central Board of Excise and Custom
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list">
                <li>
                  <a href="https://www.rbi.org.in/" target="_blank">
                    Reserve Bank of India
                  </a>
                </li>
                <li>
                  <a href="https://www.bseindia.com/" target="_blank">
                    Bombay stock Exchange Limited
                  </a>
                </li>
                <li>
                  <a href="https://www.nse-india.com/" target="_blank">
                    National Stock Exchange of India Ltd
                  </a>
                </li>
                <li>
                  <a href="http://www.dgft.gov.in/" target="_blank">
                    Director Genral Foreign Trade
                  </a>
                </li>
                <li>
                  <a href="https://finmin.nic.in/" target="_blank">
                    Ministry of Finance
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list">
                <li>
                  <a href="commerce.gov.in/" target="_blank">
                    Ministry of Commerce and Industry
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.irdai.gov.in/Defaulthome.aspx?page=H1"
                    target="_blank"
                  >
                    Insurance Regulatory and Development Authority of India
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.supremecourtofindia.nic.in/"
                    target="_blank"
                  >
                    Supreme Court of India
                  </a>
                </li>
                <li>
                  <a href="http://www.icsi.edu/" target="_blank">
                    Institute of Company Secretaries of India
                  </a>
                </li>
                <li>
                  <a href="https://www.icai.org/" target="_blank">
                    Institute of Chartered Accountants of India
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

ImportantLinks.propTypes = {};

export default ImportantLinks;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { db, axios } from '../../../firebase';
import GoogleMapReact from 'google-map-react';

const ContactUs = props => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (
      name.trim() &&
      mobileNumber.trim() &&
      email.trim() &&
      subject.trim() &&
      body.trim()
    ) {
      setSending(true);
      axios
        .post('https://us-central1-caanuragmishra.cloudfunctions.net/submit', {
          name: name,
          email: email,
          mobileNumber: mobileNumber,
          subject: subject,
          message: body,
        })
        .then(res => {
          db.collection('contact-form')
            .add({
              fullName: name,
              email: email,
              mobileNumber: mobileNumber,
              subject: subject,
              body: body,
            })
            .then(() => {
              setError(false);
              setSuccess(true);
              setSending(false);
            })
            .catch(error => {
              setError(true);
              setSuccess(false);
              setSending(false);
            });
        })
        .catch(error => {
          setError(true);
          setSuccess(false);
          setSending(false);
        });
    } else {
      setError('Please fill all the required fields');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 8000);
  }, [success]);

  return (
    <section className="contact_us" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="ctu_head text-center">
              <h1>Contact Us</h1>
            </div>
            <div className="ctu_underline"></div>
          </div>
        </div>
        <div className="row contact-padding">
          <div className="col-md-6">
            <br />
            <form id="contactform" name="contactform" onSubmit={handleSubmit}>
              <div className="form-group has-feedback">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  placeholder="Enter Your Full Name &#42;"
                  onChange={e => setName(e.target.value)}
                />
                <span id="contact_name_error"></span>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email Address &#42; E&#46;g&#46;abc@mail.com"
                  onChange={e => setEmail(e.target.value)}
                />
                <span id="contact_email_error"></span>
                <input
                  type="text"
                  className="form-control"
                  name="mobileNumber"
                  id="mobileNumber"
                  placeholder="Enter Your Mobile Number &#42;"
                  onChange={e => setMobileNumber(e.target.value)}
                />
                <span id="contact_mobile_error"></span>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Enter Your Enquiry Subject &#42;"
                  onChange={e => setSubject(e.target.value)}
                />
                <span id="enquiry_subject_error"></span>
                <textarea
                  name="body"
                  id="body"
                  placeholder="Enter your Enquiry Message &#42;"
                  className="form-control"
                  cols="30"
                  rows="6"
                  onChange={e => setBody(e.target.value)}
                ></textarea>
                <span id="enquiry_message_error"></span>
                {/* <input
                  type="file"
                  name="enquiry_attachment"
                  className="form-control-file"
                  id="enquiry_attachment"
                /> */}
                {/* <span><?php if ($msg != "") echo "$msg<br /><br />"; ?></span> */}
                <button
                  type="submit"
                  name="submit"
                  id="submit"
                  className="btn btn-general btn-blue form-control"
                  style={{ marginTop: 20 }}
                  disabled={sending}
                >
                  SEND AN ENQUIRY
                </button>
                {error ? (
                  <span
                    id="enquiry_message_error"
                    style={{
                      color: 'darkred',
                    }}
                  >
                    {typeof error === 'string'
                      ? error
                      : 'Failed to submit response. Please try again!'}
                  </span>
                ) : null}
                {success ? (
                  <div
                    className="alert alert-success text-center"
                    role="alert"
                    style={{
                      padding: 10,
                      marginTop: 5,
                      fontSize: 16,
                    }}
                  >
                    Thank you for submitting your query. We will get to you
                    soon.
                  </div>
                ) : null}
              </div>
            </form>
          </div>
          <br />
          <div className="col-md-6 a">
            <div className="alert alert-success text-center" role="alert">
              <span className="fa fa-map-marker fa-lg"></span>&nbsp;Address
              &amp; Directions
            </div>
            <div className="address">
              <p>
                Mishra Anurag &amp; Comapny
                <br /> 159, Ground Floor, Sector 2B, <br /> Vaishali, Ghaziabad,
                UP, India &#45; 201010
              </p>
            </div>
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7349760708503!2d77.33878!3d28.637704099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfad378614fb3%3A0xbf89cdf6fa6e6dc5!2sB%2F159%2C%20Block%20B%2C%20Sector%202%2C%20Vaishali%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201010!5e0!3m2!1sen!2sin!4v1682261429426!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="alert alert-success text-center" role="alert">
              <span className="fa fa-phone-square fa-lg"></span>&nbsp;Call Us
            </div>
            <div className="call_us">
              <a href="tel:+91 8285162228">
                <span className="fa fa-phone"></span>&nbsp;&nbsp;8285162228
              </a>{' '}
              &frasl; <a href="tel:+91 8317045105">8317045105</a> <br />
              <span className="fa fa-envelope"></span>&nbsp;&nbsp;
              <a href="mailto:caanuragmishra@gmail.com">
                mail@caanuragmishra.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactUs.propTypes = {};

export default ContactUs;

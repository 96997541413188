import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const PanTan = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> PAN &frasl; TAN Application </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> PAN &frasl; TAN Application </a>
            </li>
          </ul>
        </div>
      </div>
      {/*     
    <section id="ntfns" className="ntfns-wrapper section-padding">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 part-outer">
                    <div className="col-md-12 part-inner">
                        <div className="ntfns-header row">
                            <p>Latest News &amp; Events</p>
                        </div>
                        <div className="ntfns-content row demo">
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                                <li>D</li>
                                <li>E</li>
                                <li>F</li>
                                <li>G</li>
                                <li>H</li>
                                <li>I</li>
                                <li>J</li>
                            </ul>
                        </div>

                        <div className="ntfns-footer row">
                            <a href="" className="btnDown"><i className="fa fa-angle-down"></i></a>
                            <a href="" className="btnUp"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 part-outer">
                    <div className="col-md-12 part-inner">
                        <div className="ntfns-header row">
                            <p>Important Links</p>
                        </div>
                        <div className="ntfns-content row demo">
                            <ul>
                                <li><a href="http://www.mca.gov.in/" target="_blank">Ministry of Corporate Affairs</a></li>
                                <li><a href="https://www.sebi.gov.in/" target="_blank">Securities and Exchange Board of India</a></li>
                                <li><a href="http://www.servicetax.gov.in/" target="_blank">Income Tax Department of India</a></li>
                                <li><a href="http://www.cbic.gov.in/" target="_blank">Directorate of Service Tax</a></li>
                                <li><a href="http://www.cbic.gov.in/" target="_blank">Central Board of Excise and Custom</a></li>
                            </ul>
                        </div>
                        <div className="ntfns-footer row">
                            <a href="btnDown1"><i className="fa fa-angle-down"></i></a>
                            <a href="btnUp1"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 part-outer">
                    <div className="col-md-12 part-inner">
                        <div className="ntfns-header row">
                            <p>Circulars</p>
                        </div>
                        <div className="ntfns-content row demo">
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                                <li>D</li>
                                <li>E</li>
                                <li>F</li>
                                <li>G</li>
                                <li>H</li>
                                <li>I</li>
                                <li>J</li>
                            </ul>
                        </div>
                        <div className="ntfns-footer row">
                            <a href="btnDown2"><i className="fa fa-angle-down"></i></a>
                            <a href="btnUp2"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <img src="assets/images/a.jpg" alt="" />
              <h1>PAN &frasl; TAN Application</h1>
              <br />
              <p>It is easy to obtain and just some clicks away.</p>
              <p>You just need to upload some documents.</p>
              <ul>
                <li> For Individual and HUF </li>
                <p>= Proof of Identity</p>
                <p>= Proof of Residential Address</p>
                <li> Other than Individual and HUF </li>
                <p>= Proof of Identity</p>
                <p>= Proof of Address</p>
              </ul>
              <p>
                <b>
                  Proof of Identity required to apply for PAN Card by an
                  Individual who is Indian Citizen
                </b>
              </p>
              <p>Any of the following:</p>
              <ul>
                <li>
                  Copy of Aadhar card issued by Unique Identification Authority
                  of India
                </li>
                <li>Copy of voter's identity card</li>
                <li>Ration card with the applicant's photograph</li>
                <li>Copy of Passport</li>
                <li>Copy of driving license</li>
                <li>Arm's license</li>
                <li>
                  Photo Identity Card issued by central government or state
                  government or any other public sector undertaking
                </li>
                <li>Central government health scheme card</li>
                <li>
                  Bank certificate containing an attested photograph of the
                  applicant along with bank account number of the applicant
                </li>
                <li>
                  Copy of pensioner card having photograph of the applicant
                </li>
                <li>
                  Certificate of identity in original signed by a Member of
                  Parliament or Member of Legislative Assembly or Municipal
                  Councilor
                </li>
              </ul>
              <br />
              <p>
                In case of PAN card application for minors, any of the above
                documents either for parents or the minor can be submitted as
                proof of identity
              </p>
              <h3>Address Proof </h3>
              <p>Any of the following:</p>
              <ul>
                <li>
                  Aadhar card issued by Unique Identification Authority of India
                </li>
                <li>Post office passbook featuring address of the applicant</li>
                <li>Passport</li>
                <li>Passport of spouse</li>
                <li>Voter identity card with photograph</li>
                <li>Latest order for property tax assessment</li>
                <li>Driving license</li>
                <li>Domicile certificate issued by government</li>
                <li>Allotment letter</li>
                <li>Copy of electricity bills</li>
                <li>Copy of landline bills</li>
                <li>Copy of broadband connection bill</li>
                <li>Bank account statements</li>
                <li>Credit card statement</li>
              </ul>
              <b>
                <p>
                  NOTE: If submitting copy of one of the last five documents,
                  the documents should not be more than three months old.
                </p>
              </b>
              <h3>
                Documents Required for HUF(Hindu Undivided Family) PAN
                Application
              </h3>
              <p>
                Hindu Undivided families are units which joint families in India
                form if there is a family business that is being shared between
                family members. This unit is required because in such situations
                there is a joint income earned and hence the income tax is
                attributed to the whole unit. For a Hindu Undivided Family or
                HUF, a single PAN is assigned to the whole unit. The application
                process requires a certain set of documents to be submitted. All
                Hindu Undivided Families are supposed to select a karta who is
                required to carry out all official formalities on behalf of the
                HUF. These documents are listed above and are same as the
                documents that are applicable to all citizens of India.
              </p>
              <br />
              <p>
                Apart from that, they will need to submit the following
                additional documents:
              </p>
              <ul>
                <li>
                  A declaration signed by the karta of the HUF stating the name,
                  father's name and address of all the HUF members
                </li>
                <li>
                  Copy of any of the above listed proof documents of the member
                  who is supposed to act as the karta of the Hindu Undivided
                  Family
                </li>
              </ul>
              <h4>Company Need to Submit Following Documents to Apply Pan</h4>
              <p>
                Documents required by any registered company for PAN Card
                Application in India are just a couple of things.{' '}
              </p>
              <b>
                <p>
                  Document required by any company registered in India, for the
                  purpose of PAN allocation.
                </p>
              </b>
              <ul>
                <li>
                  Copy of Registration Certificate issued by the Registrar of
                  Companies
                </li>
              </ul>
              <b>
                <p>
                  Documents required for PAN application by a Company Registered
                  outside India
                </p>
              </b>
              <ul>
                <li>
                  Copy of certificate of registration issued by the Registrar of
                  Firms or copy of Partnership Deed
                </li>
                <li>
                  Copy of Registration Certificate issued in India or copy of
                  approval granted to set up company in India by Indian
                  authorities
                </li>
              </ul>
              <h4>
                Documents Required to Apply for PAN Card by a Firm (includes
                Limited Liability Partnership) registered or formed in India
              </h4>
              <p>
                A Limited Liability Partnership is where all partners have
                limited liabilities and no one partner is responsible for
                misconduct or negligence of the other partner/partners.
                Following are the two documents required by LLPs for PAN
                application in India. Either of these documents can be
                submitted.
              </p>
              <ul>
                <li>
                  Copy of Registration Certificate issued by the Registrar of
                  Limited Liability Partnerships&#44; <b>or&#58;</b>
                </li>
                <li>Copy of partnership deed</li>
              </ul>
              <h3>TAN Application</h3>
              <h4>
                Any business entity which is required to deduct TDS and TCS has
                to obtain TAN.
              </h4>
              <h4>You can easily get TAN, just upload-</h4>
              <ul>
                <li>Pan Of Business Entity </li>
                <li>
                  Address Proof Of Entity Such As- Rent Agreement, Certificate
                  OF Incorporation, Partnership Deed, Etc..{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

PanTan.propTypes = {};

export default PanTan;

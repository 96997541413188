import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const NgoTrust = props => {
  return (
    <div id="wrapper">
      <Navbar />{' '}
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> NGO Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> NGO Services </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img
                src="assets/images/Business-linkages-vital-for-SME-success-2319.jpg"
                alt=""
              />
              <h2> NGO Services </h2>
              <br />
              <h3>NGO REGISTRATION SERVICES</h3>
              <p>
                We are offering All services to our prestigious customers which
                are delivered accurately and precisely. Our experienced
                professionals are capable of developing efficient and
                cost-effective tax strategies that can help our clients in
                managing new tax laws according to the legislation and
                prescribed government norms and guidelines.
              </p>
              <br />
              <p>
                <strong>Ngo Registration Documents Required .....</strong>
              </p>

              <ul>
                <li>
                  Memorandum and Rules &amp; Regulations in Duplicate duly
                  signed by Minimum Three persons of society (Each page on
                  bottom).
                </li>
                <li>
                  Ownership Proof of Registered Office. (Electricity Bill/Sale
                  Deed/ GPA/Water Bill etc.)
                </li>
                <li>
                  Residential/Office address Proof of the all members of the
                  Society. Such as Voter ID/ Telephone Bill/Electricity
                  Bill/Pass Port/Driving License etc.{' '}
                </li>
                <li>NOC from Owner of premises on Rs.10/- Stamp Paper.</li>
                <li>
                  Affidavit from President (for Blood Relation) on Rs.10/- Stamp
                  Paper.
                </li>
                <li>
                  List of Desirous person should be signed by all members of the
                  society.
                </li>
                <li>
                  Desirous persons or the persons subscribing the name to the
                  Memorandum should not be (in any case) less than seven. If it
                  is proposed to give All India character to the society there
                  must be minimum of eight different persons from different
                  States of Indian Union to the Memorandum.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

NgoTrust.propTypes = {};

export default NgoTrust;

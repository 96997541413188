import React from 'react';
import PropTypes from 'prop-types';

const Services = props => {
  return (
    <section id="services" className="services">
      <div className="content">
        <div className="content-title">
          <h3>Services </h3>
          <div className="content-title-underline"></div>
        </div>
        <div className="content-para">
          <p>
            Providing a Comprehensive range of financial and compliance
            services.
          </p>
        </div>
      </div>
      <div className="arrow"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12">
              <div className="service-item">
                <img
                  src="assets/images/incometax.jpg"
                  width="100%"
                  height="200px"
                  alt=""
                />
                <br />
                <br />
                <h4 className="text-center"> Income Tax Return </h4>
                <p className="text-justify">
                  We are always with you to assist in your Income Tax Return
                  filing. Get helped from our dedicated team of experts.
                </p>
                <br />
                <br />
                <a
                  href="/itr"
                  className="btn btn-general btn-service btn-block"
                >
                  Read More ...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12">
              <div className="service-item">
                <img
                  src="assets/images/about_imgi_updated.png"
                  width="100%"
                  height="200px"
                  alt=""
                />
                <br />
                <br />
                <h4 className="text-center"> GST Returns </h4>
                <p className="text-justify">
                  Whether it is New Registration under GST or GST Return filing
                  or GST Audit, our specialised team helps you to get it done in
                  easily.
                </p>
                <br />
                <a
                  href="/gst"
                  className="btn btn-general btn-service btn-block"
                >
                  Read More ...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12">
              <div className="service-item">
                <img
                  src="assets/images/free-business-plan-templates.jpg"
                  width="100%"
                  height="200px"
                  alt=""
                />
                <br />
                <br />
                <h4 className="text-center"> Startup Services </h4>
                <p className="text-justify">
                  Get your Startup dream come true. Get your idea take shape and
                  manage all your compliances with our best-in-className support
                  services.
                </p>
                <br />
                <a
                  href="/startup"
                  className="btn btn-general btn-service btn-block"
                >
                  Read More ...
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12">
              <div className="service-item">
                <img
                  src="assets/images/TDS.jpg"
                  width="100%"
                  height="200px"
                  alt=""
                />
                <br />
                <br />
                <h4 className="text-center"> TDS/TCS Services </h4>
                <p className="text-justify">
                  For tax deducted or collected at source on certain payments a
                  quarterly TDS/TCS statement is required to be submitted. Our
                  Team is always available to help you for this.
                </p>
                <br />
                <a
                  href="/tds-tcs"
                  className="btn btn-general btn-service btn-block"
                >
                  Read More ...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12">
              <div className="service-item">
                <img
                  src="assets/images/reg.jpg"
                  width="100%"
                  height="200px"
                  alt=""
                />
                <br />
                <br />
                <h4 className="text-center"> Registrations </h4>
                <p className="text-justify">
                  We assist in a wide array of registrations and certification
                  requirement for business. To name a few GST registrations,
                  Company registrations, Trademarks, ISO Certifications etc.
                </p>
                <a
                  href="/reg"
                  className="btn btn-general btn-service btn-block"
                >
                  Read More ...
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12">
              <div className="service-item">
                <img
                  src="assets/images/advisory.jpg"
                  width="100%"
                  height="200px"
                  alt=""
                />
                <br />
                <br />
                <h4 className="text-center"> Business Advisory </h4>
                <p className="text-justify">
                  Other than regular compliance services our team assists in
                  business domain specific advisory and research.
                </p>
                <br />
                <br />
                <br />
                <a
                  href="/advisory"
                  className="btn btn-general btn-service btn-block"
                >
                  Read More ...
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </section>
  );
};

Services.propTypes = {};

export default Services;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Itr = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> Income Tax Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> Income Tax Services </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <section id="ntfns" className="ntfns-wrapper section-padding">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 part-outer">
                    <div className="col-md-12 part-inner">
                        <div className="ntfns-header row">
                            <p>Latest News &amp; Events</p>
                        </div>
                        <div className="ntfns-content row demo">
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                                <li>D</li>
                                <li>E</li>
                                <li>F</li>
                                <li>G</li>
                                <li>H</li>
                                <li>I</li>
                                <li>J</li>
                            </ul>
                        </div>

                        <div className="ntfns-footer row">
                            <a href="" className="btnDown"><i className="fa fa-angle-down"></i></a>
                            <a href="" className="btnUp"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 part-outer">
                    <div className="col-md-12 part-inner">
                        <div className="ntfns-header row">
                            <p>Important Links</p>
                        </div>
                        <div className="ntfns-content row demo">
                            <ul>
                                <li><a href="http://www.mca.gov.in/" target="_blank">Ministry of Corporate Affairs</a></li>
                                <li><a href="https://www.sebi.gov.in/" target="_blank">Securities and Exchange Board of India</a></li>
                                <li><a href="http://www.servicetax.gov.in/" target="_blank">Income Tax Department of India</a></li>
                                <li><a href="http://www.cbic.gov.in/" target="_blank">Directorate of Service Tax</a></li>
                                <li><a href="http://www.cbic.gov.in/" target="_blank">Central Board of Excise and Custom</a></li>
                            </ul>
                        </div>
                        <div className="ntfns-footer row">
                            <a href="btnDown1"><i className="fa fa-angle-down"></i></a>
                            <a href="btnUp1"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 part-outer">
                    <div className="col-md-12 part-inner">
                        <div className="ntfns-header row">
                            <p>Circulars</p>
                        </div>
                        <div className="ntfns-content row demo">
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                                <li>D</li>
                                <li>E</li>
                                <li>F</li>
                                <li>G</li>
                                <li>H</li>
                                <li>I</li>
                                <li>J</li>
                            </ul>
                        </div>
                        <div className="ntfns-footer row">
                            <a href="btnDown2"><i className="fa fa-angle-down"></i></a>
                            <a href="btnUp2"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <img src="assets/images/incometax.jpg" alt="" />
              <h1>Requirements for Filing ITRs...</h1>
              <br />
              <ul>
                <li> Individuals and NRIs </li>
                <p>
                  Required to file income tax return, if their income exceeds
                  Rs.2.5 lakhs per annum.
                </p>
                <li> Partnership firms </li>
                <p>
                  Required income tax return - irrespective of amount of income
                  or loss.
                </p>
                <li> Companies and LLPs </li>
                <p>
                  mandatorily required to file income tax return, irrespective
                  of turnover or profit.
                </p>
              </ul>
              <h4>ITRs Due Date</h4>
              <p>
                For Individual taxpayers the due date for income tax return
                filing is 31st July
              </p>
              <p>
                For companies and taxpayer requiring tax audit is 30th September
              </p>
              <h4>Tax Audit Limit: </h4>
              <ul>
                <li>Business</li>
                <p>
                  In case of a business, tax audit would be required if the
                  total sales turnover or gross receipts in the business exceeds
                  Rs.1 crore in any previous year. Under the Income Tax Act,
                  “Business” simply means any economic activity carried on for
                  earning profits. Section 2(3) has defined the business as “any
                  trade, commerce, manufacturing activity or any adventure or
                  concern in the nature of trade, commerce and manufacture”.
                </p>
                <li>Profession</li>
                <p>
                  In case of a profession or professional, tax audit would be
                  required if gross receipts in the profession exceeds Rs.50
                  lakhs in any of the previous year. A profession or
                  professional could be any of the following as per Rule 6F of
                  the Income Tax Rules, 1962:
                  <br />
                  1. Architect
                  <br />
                  2. Accountant
                  <br />
                  3. Authorised representative
                  <br />
                  4. Engineer
                  <br />
                  5. Film Artist – Actor, Cameraman, Director, Music Director,
                  Editor, etc.
                  <br />
                  6. Interior Decorator
                  <br />
                  7. Legal Professional – Advocate or Lawyer
                  <br />
                  8. Medical Professional – Doctor, Physiotherapist, etc.,
                  <br />
                  9. Technical Consultant
                </p>
              </ul>
              <h4>Presumptive Taxation Scheme</h4>
              <p>
                If a person is enrolled under the presumptive taxation scheme
                under section 44AD and total sales or turnover is more than Rs.
                2 crores, then tax audit would be required.Also, any person
                enrolled under the presumptive taxation scheme who claims that
                the profits of the business are lower than the profits
                calculated in accordance with the presumptive taxation scheme
                would be required to obtain a tax audit report.
              </p>
              <h4>Due Date for Filing Tax Audit Report</h4>
              <p>
                The due date for completing and filing tax audit report under
                section 44AB of Income Tax Act is 30th September of the
                assessment year. Hence, if a taxpayer is required to obtain tax
                audit, then he or she would be required to file income tax
                return on or before 30th September along with the tax audit
                report. In case the taxpayer is also liable for transfer pricing
                audit, then the due date for filing tax audit is 30th November
                of the assessment year.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Itr.propTypes = {};

export default Itr;

import React from 'react';
import PropTypes from 'prop-types';

function Navbar(props) {
  return (
    <>
      <section className="up-head">
        <div className="container">
          <div className="icons pull-left">
            <ul>
              <li>
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-google-plus"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="icons pull-right">
            <ul>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">Faq</a>
              </li>
              <li>
                <a href="#">Help</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="up-head-mobile">
        <a
          data-toggle="collapse"
          href="#mobile-support"
          style={{
            background: '#34c6d3',
            color: 'white',
            padding: '5px 20px',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            boxShadow: '0 0 10px silver',
          }}
          className="btn-general btn-blue btn text-center"
        >
          <i className="fa fa-1x fa-question-circle"></i>
        </a>
        <div className="mobile-support" id="mobile-support">
          <ul>
            <li>
              <a href="#">Support</a>
            </li>
            <li>
              <a href="#">Faq</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li>
          </ul>
        </div>
      </section>
      <nav className="navbar navbar-default">
        <div className="container">
          <div className="navbar-header tb-pad">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="true"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="/">
              <img
                alt="Brand"
                src="assets/images/header_logo2.png"
                className="img-responsive"
              />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="nav navbar-nav tb-pad">
              <li>
                <a href="/">Home</a>
              </li>
              <li className="dropdown">
                <a>About Us&nbsp;</a>
                <div className="dropdown-content">
                  <a href="/aboutus">About the Firm</a>
                  <a href="/aboutus">Ethics And Culture</a>
                  <a href="/aboutus">Team</a>
                </div>
              </li>
              <li className="dropdown">
                <a>Services&nbsp;</a>
                <div className="dropdown-content">
                  <a className="submenu">Income Tax</a>
                  <div className="sub-menu">
                    <a href="/itr"> ITR Filing </a>
                    <a href="/pan-tan"> PAN &frasl; TAN Application </a>
                    <a href="/12a80greg"> 12A &amp; 80G Registration </a>
                  </div>
                  <a href="/gst">GST</a>
                  <a className="submenu1">Company and Law Services</a>
                  <div className="sub-menu1">
                    <a href="/registrations"> Registrations </a>
                    <a href="/compliances"> Compliances </a>
                  </div>
                  <a href="/audit-assurance">Audit and Assurance</a>
                  <a href="/tds-tcs">TDS/TCS</a>
                  <a href="/accounting">Bookkeeping and Accounting</a>
                  <a href="/advisory">Business Advisory</a>
                  <a href="/advisory2">
                    Investment and Project Finance Advisory
                  </a>
                  <a href="/ngo-trust">NGO/Society/Trust</a>
                  <a className="submenu2">Import/Export</a>
                  <div className="sub-menu2">
                    <a href="/iec"> IEC </a>
                    <a href="/compliances"> Compliances </a>
                  </div>
                  <a href="/fcra">FCRA Registration</a>
                  <a href="/trademark">Trademark Services</a>
                </div>
              </li>

              <li>
                <a href="/important-links">Important Links</a>
              </li>
              <li className="dropdown">
                <a href="/">Work with Us</a>
                <div className="dropdown-content">
                  <a href="/apply">Apply Online</a>
                </div>
              </li>
              <li>
                <a href="/#contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

Navbar.propTypes = {};

export default Navbar;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const TdsTcs = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> TDS/TCS SERVICES </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> TDS/TCS SERVICES </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/TDS.jpg" alt="" />
              <h2> TDS/TCS SERVICES </h2>
              <br />
              <h3>TDS ( TAX DEDUCTION AT SOURCE )</h3>
              <p>
                TDS, Tax deduction at source means the tax required to be paid
                by the assesses, is deducted by the person paying the income to
                him. Thus, the tax is deducted at the source of income
                itself.Tax Deducted at Source (TDS) is a means of collecting
                income tax in India, governed under the Indian Income Tax Act of
                1961. It is managed by the Central Board for Direct Taxes (CBDT)
                and is part of the Department of Revenue managed by Indian
                Revenue Service (IRS).It has a great importance while conducting
                Tax Audits. TDS is based on the principle of ''pay as and when
                you earn''.TDS is a win-win scenario for both the taxpayers and
                the government. Tax is deducted when making payments through
                cash, credit or cheque, which is then deposited with the central
                agencies.{' '}
              </p>
              <br />
              <h3>TCS ( TAX COLLECTION AT SOURCE ) </h3>
              <p>
                Tax Collected at Source is known as TCS. It means tax being
                collected from the source by the seller (collector) from the
                buyer (Collectee/payee) for goods traded under section 206C(1)
                of Income Tax Act, 1961. TCS is collected when acquired or when
                paid whichever is earlier. Tax collected at source is applicable
                to any business or trade dealing with alcoholic liquor, forest
                produce, scrap, etc. It also includes lease, license, or
                contract related to parking lots, toll plaza, mines, and quarry,
                etc as defined in section 206C of Income Tax Act, 1961. For TDS
                and TCS registration one has to make an application for TAN (Tax
                Deduction Account Number ).Click
                Herehttp://www.myfinconsultants.com/PAN_and_TAN{' '}
              </p>
              <br />
              <h3>TDS AND TCS RETURN</h3>
              <p>
                For any tax deducted or collected at source on certain payments,
                on or after 1st April 2010, a quarterly TDS/TCS statement is
                required to be submitted.The Form in which these quarterly
                returns have to be submitted is specific to the nature of
                deduction.
              </p>
              <ul>
                <li>Form 24Q TDS on Salary</li>
                <li>Form 27EQ Tax</li>
                <li>Form 24Q TDS on Salary</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

TdsTcs.propTypes = {};

export default TdsTcs;

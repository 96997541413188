import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const AboutUs = props => {
  return (
    <div id="wrapper">
      <Navbar />
      {/* <Carousel /> */}
      {/* <Services /> */}
      <AboutTheFirm />
      <section className="ethiculture" id="ethiculture"></section>
      <section className="team" id="team">
        <div className="content" style={{ background: 'white' }}>
          <div className="content-title">
            <h3 style={{ color: '#34c6d3', padding: '10px 0' }}>Our Team </h3>
            <div className="content-title-underline"></div>
          </div>
          <div className="content-para"></div>
        </div>
        <div className="container-fluid">
          <div className="row" style={{ marginBottom: 40 }}>
            <div className="col-md-6">
              <div className="team-member">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src="assets/images/member1.png"
                      width="180px"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <h4>Ca Anurag Mishra</h4>
                    <h5>FCA, DISA, CCA (ICAI)</h5>
                    <h5>Partner</h5>
                    <div className="social">
                      <a href="" className="fa fa-linkedin"></a>
                      <a href="" className="fa fa-facebook"></a>
                      <a href="" className="fa fa-twitter"></a>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-general btn-blue btn-block"
                  id="showa"
                >
                  Show Detail
                </button>
              </div>
              <div className="arrow1 ns"></div>
            </div>
            <div className="col-md-6">
              <div className="team-member">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src="assets/images/member.png"
                      style={{ minHeight: 250 }}
                      width="180px"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <h4>Ca Anubha Tiwari</h4>
                    <h5>FCA, DISA, CCA (ICAI)</h5>
                    <h5>Partner</h5>
                    <div className="social">
                      <a href="" className="fa fa-linkedin"></a>
                      <a href="" className="fa fa-facebook"></a>
                      <a href="" className="fa fa-twitter"></a>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-general btn-blue btn-block"
                  id="showb"
                >
                  Show Detail
                </button>
              </div>
              <div className="arrow2 ns"></div>
            </div>
          </div>
          <div className="row member-detail ns" id="detail1">
            <div className="panel panel-default">
              <div className="panel-header">
                <h4 className="text-center">Ca Anurag Mishra</h4>
              </div>
              <div className="panel-body">
                <div className="body-content">
                  <p>
                    Anurag Mishra is a fellow member of Institute of Chartered
                    Accountants of India. He has wide experience and expertise
                    in Audit, Taxation and Finance. He has qualified DISA and
                    CCA also. He is involved in various stream of Audit and
                    Compliance work including:
                  </p>
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Handling Bank Audits (including Stock Audit, Concurrent
                      Audit, Statutory Audit, Revenue Audit, IS Audit etc.)
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Income
                      Tax Cases apart from Tax Audits
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Internal Audit of PSUs and Listed Companies
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Consultancy on Conversion of Single Entry System to Double
                      Entry System{' '}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Statutory Compliance work of PSUs and Listed Companies
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Formed
                      more than 100 Companies and handling ROC related works
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Consultancy on Trade Mark, Copyrights, VAT related matters
                      etc
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Consultancy to Pharma, Engineering, Advertising, Health
                      and Fitness, Telecom, Architectural Companies
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row member-detail ns" id="detail2">
            <div className="panel panel-default">
              <div className="panel-header">
                <h4 className="text-center">Ca Anubha Tiwari</h4>
              </div>
              <div className="panel-body">
                <div className="body-content">
                  <p>
                    Anubha Tiwari is a fellow member of Institute of Chartered
                    Accountants of India. She has rich experience of working for
                    Big Corporates and listed companies. She was previously
                    working as CFO for a reputed listed company. She is involved
                    in various stream of Audit and Compliance work including:
                  </p>
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Various
                      reporting to Stock Exchanges and RBI.
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Handling Bank Audits (including Stock Audit, Concurrent
                      Audit, Statutory Audit, Revenue Audit, IS Audit etc.)
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Income
                      Tax Cases apart from Tax Audits
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Sales
                      Tax/Service Tax/ GST cases
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Internal Audit of PSUs and Listed Companies
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Formed
                      more than 100 Companies and handling ROC related works
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Consultancy on Trade Mark, Copyrights, VAT related matters
                      etc
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      Consultancy to Solar Power, Pharma, Advertising, Health
                      and Fitness, Telecom, Architectural Companies
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

AboutUs.propTypes = {};

export default AboutUs;

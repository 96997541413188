import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from './../../components/organisms';
import { colors } from '@mui/material';

const Home = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <Carousel />
      <Services />
      <AboutTheFirm />
      <ContactUs />
      <Footer />
    </div>
  );
};

Home.propTypes = {};

export default Home;

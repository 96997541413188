import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Carousel,
  Footer,
  Services,
  AboutTheFirm,
  ContactUs,
} from '../../components/organisms';
import { colors } from '@mui/material';

const Iec = props => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="content-header">
        <div className="content-title">
          <h1 className="text-center"> Import/Export Services </h1>
          <ul className="breadcrumb">
            <li>
              <a href="index">Home</a>
            </li>
            <li>
              <a href="itr"> Import/Export Services </a>
            </li>
          </ul>
        </div>
      </div>
      <section id="services_pages" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-justify">
              <img src="assets/images/servicesprint.jpg" alt="" />
              <h2> Import/Export Services </h2>
              <br />
              <h3>What is IEC?</h3>
              <p>
                To import in India or export out of India, IEC Code i.e.
                Importer Exporter Code is mandatory. IEC Code is unique 10 digit
                code issued by DGFT – Director General of Foreign Trade,
                Ministry of Commerce, Government of India. No person or entity
                shall make any Import or Export without IEC Code Number.
              </p>
              <br />
              <h3>Eligibility and other provisions for applying IEC</h3>
              <p>
                No export or import shall be made by any person without an
                Importer-Exporter Code (IEC) Number unless specifically
                exempted. An Importer/Exporter Code (IEC) number shall be
                granted on application to the competent authority. Every
                application for grant of IEC number shall be made by the
                Registered/Head Office of the applicant, unless otherwise
                specified, shall be submitted to the Regional Authority of
                Directorate General Foreign Trade, as per the territorial
                jurisdiction of the Regional authorities and shall be
                accompanied by documents prescribed therein. In case of STPI/
                EHTP/ BTP units, the Regional Offices of the DGFT having
                jurisdiction over the district in which the Registered/ Head
                Office of the STPI unit is located shall issue or amend the
                IECs.{' '}
              </p>
              <h3>
                For getting IEC now Digital Signature has become mandatory.
              </h3>
              <p>Now only three documents are required for getting IEC </p>
              <ul>
                <li>Photo of Proprietor/Partner/Director </li>
                <li>Pan Card</li>
                <li>Cancelled Cheque in the Name of Firm. </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

Iec.propTypes = {};

export default Iec;

import React from 'react';
import PropTypes from 'prop-types';

const AboutTheFirm = props => {
  return (
    <section className="about-section">
      <div className="border"></div>
      <div id="about-bg-diagonal" className="bg-parallax"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div id="about-content">
              <div id="about-content-outer">
                <div id="about-content-inner">
                  <div className="content-title">
                    <h3>About the Firm</h3>
                    <div className="content-title-underline"></div>
                  </div>
                  <div id="about-desc">
                    <p>
                      Mishra Anurag &amp; Company , a Chartered Accountants
                      Firm, is providing wide array of consultancy and financial
                      services to many MNCs and reputed Indian Companies. We at
                      Mishra Anurag &amp; Company put strong focus on client
                      satisfaction with delivering excellence in Audit,
                      Assurance, Taxation and business advisory services.
                    </p>
                  </div>
                  <div id="about-btn">
                    <a
                      href="/aboutus"
                      className="btn btn-lg btn-general btn-blue"
                    >
                      {' '}
                      Read More ...{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border"></div>
    </section>
  );
};

AboutTheFirm.propTypes = {};

export default AboutTheFirm;

import firebase from 'firebase/compat/app';
// import 'firebase/auth';
import 'firebase/compat/analytics';
// import 'firebase/messaging';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import axios from 'axios';

let firebaseConfig = {};

// console.log(process.env.REACT_APP_TARGET_ENV);

if (process.env.REACT_APP_TARGET_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyDzRFp4djf8B4_TYvyrc1PS_cDce0sA2yQ',
    authDomain: 'caanuragmishra.firebaseapp.com',
    projectId: 'caanuragmishra',
    storageBucket: 'caanuragmishra.appspot.com',
    messagingSenderId: '555018249663',
    appId: '1:555018249663:web:672e3238a8b075481fad0a',
    measurementId: 'G-Y1H2WCK2J3',
  };
}
if (process.env.REACT_APP_TARGET_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyDzRFp4djf8B4_TYvyrc1PS_cDce0sA2yQ',
    authDomain: 'caanuragmishra.firebaseapp.com',
    projectId: 'caanuragmishra',
    storageBucket: 'caanuragmishra.appspot.com',
    messagingSenderId: '555018249663',
    appId: '1:555018249663:web:672e3238a8b075481fad0a',
    measurementId: 'G-Y1H2WCK2J3',
  };
}

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
firebase.analytics();
if (process.env.REACT_APP_TARGET_ENV === 'development') {
  firebase.analytics().setAnalyticsCollectionEnabled(false);
}

// if (process.env.NODE_ENV === 'development') {
//   firebase
//     .auth()
//     .useEmulator('http://localhost:9099/', { disableWarnings: true });
// }

export { db, axios };
